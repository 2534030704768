import React from 'react';
import ReactDOM from 'react-dom/client';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import ReactJsAlert from "reactjs-alert"
import './Morisfa_global_css_components_webpack/css/style.css';
 import './Morisfa_global_css_components_webpack/css/bootstrap.min.css';
import './Morisfa_global_css_components_webpack/css/animate.css';
import './Morisfa_global_css_components_webpack/css/owl.carousel.min.css';
import './Morisfa_global_css_components_webpack/css/jquery.fancybox.css';
import './Morisfa_global_css_components_webpack/webkit/webkit.css';
import './Morisfa_global_css_components_webpack/css/font-awesome.css';
import './Morisfa_global_css_components_webpack/css/media.css';
import { useRef, useState } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/scrollbar";
import "swiper/css/navigation";
import "swiper/css/pagination";
import {Autoplay, Keyboard, Scrollbar, Navigation, Pagination } from "swiper";





const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));
// function activateLasers(){
//   return(
//       <ReactJsAlert
//           status={true}   // true or false
//           type="success"   // success, warning, error, info
//           title="Hey! this is an alert."   // title you want to display
//           Close={() => this.setState({ status: false })}   // callback method for hide
//       />
//   )
//   alert('ojj')
// }



class App extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      type: "success",
      status: false,
      title: "This Is an Spa page just For Resume 🤭 Maybe , Maybe later, if I was unemployed in the company 😒, I would add something new to it 🤓",
    };
  }



 activateLasers = () => {

alert('sadasd')


  }

  render() {

  return (










      <div>
        <ReactJsAlert
            status={this.state.status} // true or false
            type={this.state.type} // success, warning, error, info
            title={this.state.title}
            Close={() => this.setState({ status: false })}
        />

        <div id="site-head" style={{
         backgroundImage: "url(/images/background-785x1024.jpg)",backgroundPosition: "center top",
    backgroundSize: "cover" }}>
          {/* Top Header Section Begins */}
          <div className="top-head">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <ul className=" pull-right">
                    <li> <i className="fa fa-clock-o" aria-hidden="true" />Look for Better Job Position </li>
                    <li>
                      <a onClick={() => this.setState({ status: true })}> <i className="fa fa-user" aria-hidden="true" /> Register </a>
                    </li>
                    <li>
                      <a  onClick={() => this.setState({ status: true })} > <i className="fa fa-lock" aria-hidden="true" /> Login </a>
                    </li>
                    <li>
                      <a  onClick={() => this.setState({ status: true })} > <i className="fa fa-shopping-cart" aria-hidden="true" /> Cart </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/* Top Header Section Ends */}
          {/* Header Section Begins */}
          <header id="header" className="float-panel" data-top={0} data-scroll={100}>
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 main-menu">
                  <div className="menuBar scrollbtn">
                    <nav className="navbar navbar-default">
                      <div className="skewbox"> </div>
                      <div className="container-fluid">
                        {/* Brand and toggle get grouped for better mobile display */}
                        <div className="navbar-header">
                          <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
                            <span className="sr-only">Toggle navigation</span>
                            <span className="icon-bar" />
                            <span className="icon-bar" />
                            <span className="icon-bar" />
                          </button>
                          <div className="logo">
                            <a className="navbar-brand" href="index.html"><img src="images/logo.png" alt="logo" /></a>
                          </div>
                        </div>
                        {/* Collect the nav links, forms, and other content for toggling */}
                        <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                          <ul className="nav navbar-nav navbar-right">

                            <li><a href="#feature">Posts</a></li>
                            <li><a href="#class">INFORMATION </a></li>
                            <li><a href="#products">INTERESTING</a></li>
                            <li><a href="#plans">Pricing </a></li>
                            <li><a href="#footer">Contact us</a></li>
                          </ul>
                        </div>
                      </div>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </header>
          {/* Header Section Ends */}
          {/* Banner Section Begins */}

          <section className="banner wow animated fadeInLeft" data-wow-delay="0.5s">
            <div className="container pr">
              <div className="banskew" />
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div className="bannerText">
                    <h1 className="slideout"> BEST </h1>
                    <h3> DESIGN AND
                       <br />SEO WEB WITH </h3>
                    <h1>MORISFA</h1>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* Banner Section Ends */}
          {/* Scroll Down Section Begins */}
          <section className="scrollDown scrollbtn">
            <h6> <a href="#boxes"> <i className="fa fa-long-arrow-left" aria-hidden="true" /> Scroll Down </a> </h6>
          </section>
          {/* Scroll Down Section Ends */}
        </div>
        <div className="main" style={{backgroundImage : "url(images/bg.jpg)"}}>
          {/* Box Section Begins */}
          <section id="boxes" className="boxes pad-top-120 pad-bottom-110">
            <div className="container">
              <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 no-padding">
                  <div className="box box-1">
                    <figure><img src="/images/1.jpeg" alt="image" /></figure>
                    <div className="boxInfo">
                      <h3> Wp Front&Back </h3>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 no-padding">
                  <div className="box box-2">
                    <figure><img src="/images/2.jpeg" alt="image" /></figure>
                    <div className="boxInfo">
                      <h3> Laravel Like a pro </h3>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 no-padding">
                  <div className="box box-3">
                    <figure><img src="/images/3.jpeg" alt="image" /></figure>
                    <div className="boxInfo">
                      <h3> WEbSocket Crypto </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* Box Section Ends */}
          {/* About Section Begins */}
          <section id="about" className="about pad-top-110 pad-bottom-120">
            <div className="container-fluid no-padding">
              <div className="row no-margin">
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 wow animated fadeInLeft" data-wow-delay="0.5s">
                  <figure><img src="/images/whois-1.png" alt="image" /></figure>
                </div>
                <div className="container">
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 pull-right wow animated fadeInRight" data-wow-delay="0.5s">
                      <h6 className="titleTop"> WELCOME TO MORISFA WEB </h6>
                      <h2 className="sectionTitle">WHO IS  <span>MORISFA?</span></h2>
                      <p> Morisfa is the nickname of an Iranian programmer's , the exact information of which you can see in the PDF resume. </p>
                      <p> Morisfa always tries to do their job cleanly and interact well with the team (except in the mornings when he is sleepy and in the evenings when he is asleep and sleepy at night, the rest is good-natured) 😁  </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* About Section Ends */}
          {/* Feature Section Begins */}
          <section style={{"backgroundImage":"url('/images/mm.png')","backgroundRepeat":"no-repeat","backgroundSize":"cover","backgroundPosition":"top right","position":"relative"}} id="feature" className="feature pad-top-115 pad-bottom-115">
            <div className="container pr">
              <div className="secHead wow fadeInLeft animated" data-wow-delay="0.5s">
                <div className="skewpink" />
                <div className="row">
                  <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                    <div className="titleText">
                      <h6 className="titleTop">YOU CAN SEE HERE MY PORTFOLIOS (Better Than in CV)</h6>
                      <h2 className="sectionTitle pad-bottom-60"><span>PORTFOLIOS</span></h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">





                </div>
              </div>

          </section>
          {/* Feature Section Ends */}
          {/* Class Section Begins */}
          <section id="class" className="class pad-top-115 pad-bottom-115">
            <div className="container pr">
              <div className="secHead wow fadeInLeft animated" data-wow-delay="0.5s">
                <div className="skewpink" />
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div className="titleText">
                      <h6 className="titleTop">Small letter of introduction</h6>
                      <h2 className="sectionTitle"> <span>Information</span></h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row wow fadeInUp animated" data-wow-delay="0.5s">

                <div id="classbox" className="grid clickable clearfix">
                  <div className="elemnt celement col-lg-3 col-md-4 col-sm-4 col-xs-12 transition">
                    <div className="element">
                      <div className="blckbox">
                        <p className="tttrtt"> More in Pdf cv </p>
                      </div>
                      <img src="images/back.png" className="fIcons flaticon-barbell" />
                      <h4> Good Backend </h4>
                      <p>Good skills and experience in php and its frameworks </p>
                      <div className="element-btn"><button  onClick={() => this.setState({ status: true })} type="button" className="element-fill-btn">Can see CV ?</button></div>
                    </div>
                  </div>
                  <div className="elemnt celement col-lg-3 col-md-4 col-sm-4 col-xs-12 transition mon sat">
                    <div className="element">
                      <div className="blckbox">
                        <p className="tttrtt"> More in Pdf cv </p>
                      </div>
                      <img src="/images/front.png" className="fIcons flaticon-boxing-mannequin" />
                      <h4> Hand on Front </h4>
                      <p> Experiences in the ّFrontEnd like (Html,Css,Bootstrap,Jquery,Next/react And Update in Cv) </p>
                      <div className="element-btn"><button  onClick={() => this.setState({ status: true })} type="button" className="element-fill-btn">Can see CV ?</button></div>
                    </div>
                  </div>
                  <div className="elemnt celement col-lg-3 col-md-4 col-sm-4 col-xs-12 transition tue fri sat">
                    <div className="element">
                      <div className="blckbox">
                        <p className="tttrtt"> More in Pdf cv </p>
                      </div>
                      <img src="/images/linux.png" className="fIcons flaticon-boxing-mannequin" />
                      <h4> Good Linux Khonws </h4>
                      <p> Meet the need to work with Linux, set up security port switching server and firewall, whm management And Exact In Cv </p>
                      <div className="element-btn"><button  onClick={() => this.setState({ status: true })} type="button" className="element-fill-btn">Can see CV ?</button></div>
                    </div>
                  </div>
                  <div className="elemnt celement col-lg-3 col-md-4 col-sm-4 col-xs-12 transition wed fri">
                    <div className="element">
                      <div className="blckbox">
                        <p className="tttrtt"> More in Pdf cv </p>
                      </div>
                      <img src="/images/more.png" className="fIcons flaticon-boxing-mannequin" />
                      <h4> More On Cv </h4>
                      <p> You can Download pdf Cv and Get All About </p>
                      <div className="element-btn"><button onClick={() => this.setState({ status: true })}  type="button" className="element-fill-btn">Can see CV ?</button></div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </section>
          {/* Class Section Ends */}


          {/* Gallery Section Begins */}

          {/* Gallery Section Ends */}
          {/* Products Section Begins */}
          <section id="products" className="products pad-top-115 pad-bottom-115">
            <div className="container pr">
              <div className="secHead wow fadeInLeft animated" data-wow-delay="0.5s">
                <div className="skewpink" />
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div className="titleText">
                      <h6 className="titleTop">Let's learn a little</h6>
                      <h2 className="sectionTitle">it's <span> interesting</span></h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row wow animated fadeInUp" data-wow-delay="0.5s">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <p className="procap"> These were the last things I got involved with when I was completing this site 😬</p>
                </div>
                <div >








                  <Grid alignItems="center"
                        justify="center" container spacing={2} columns={10} sx={{ flexDirection: { xs: "column", md: "row"} }}>



                    <Grid sx={{ maxWidth: 'unset' }} item xs={3}>


                      <div className="item">
                        <div className="element">
                          <div className="blckbox">
                            <h6> 💪 </h6>
                          </div>
                          <img src="/images/socket.png" alt="images" />
                          <h4> Php Websocket Problem On Wss When Deploy On server </h4>
                          <p style={{color: "#000000"}}> A gift from my experiences 🤤 </p>
                          <div className="priceBox">

                            <h4> I got involved 9 months ago for a week </h4>
                          </div>
                          <ul className="stars">
                            <li>
                              <a href="#"> <i className="fa fa-star" aria-hidden="true" /> </a>
                            </li>
                            <li>
                              <a href="#"> <i className="fa fa-star" aria-hidden="true" /> </a>
                            </li>
                            <li>
                              <a href="#"> <i className="fa fa-star" aria-hidden="true" /> </a>
                            </li>
                            <li>
                              <a href="#"> <i className="fa fa-star" aria-hidden="true" /> </a>
                            </li>
                            <li>
                              <a href="#"> <i className="fa fa-star-o" aria-hidden="true" /> </a>
                            </li>
                          </ul>
                          <div className="buy-buttons">
                          <a target="_blank" href="https://www.digitalocean.com/community/tutorials/how-to-use-apache-as-a-reverse-proxy-with-mod_proxy-on-centos-7" > <button type="button" className="fill-btn">php socket problem?see</button></a>
                            <button  onClick={() => this.setState({ status: true })}  type="button" className="fill-btn circled"><i className="fa fa-heart" aria-hidden="true" /></button>
                            <button  onClick={() => this.setState({ status: true })} type="button" className="fill-btn circled"><i className="fa fa-shopping-cart" aria-hidden="true" /></button>
                          </div>
                        </div>
                      </div>

                    </Grid>
                    <Grid sx={{ maxWidth: 'unset' }} item xs={3}>


                      <div className="item">
                        <div className="element">
                          <div className="blckbox">
                            <h6> 😴</h6>
                          </div>
                          <img style={{width: "163px"}} src="/images/nv.jpg" alt="images" />
                          <h4> redux react alternatives </h4>
                          <p style={{color: "#000000"}}> Is there an easier way than Redax? 👵  </p>
                          <div className="priceBox">

                            <h4> Redux bored me, because Frant is not my main specialty 😤</h4>
                          </div>
                          <ul className="stars">
                            <li>
                              <a href="#"> <i className="fa fa-star" aria-hidden="true" /> </a>
                            </li>
                            <li>
                              <a href="#"> <i className="fa fa-star" aria-hidden="true" /> </a>
                            </li>
                            <li>
                              <a href="#"> <i className="fa fa-star" aria-hidden="true" /> </a>
                            </li>
                            <li>
                              <a href="#"> <i className="fa fa-star" aria-hidden="true" /> </a>
                            </li>
                            <li>
                              <a href="#"> <i className="fa fa-star-o" aria-hidden="true" /> </a>
                            </li>
                          </ul>
                          <div className="buy-buttons">
                            <a target="_blank" href="https://blog.bitsrc.io/redux-react-alternatives-c1733793a339" >      <button type="button" className="fill-btn">See alternatives</button></a>
                            <button type="button" className="fill-btn circled"><i className="fa fa-heart" aria-hidden="true" /></button>
                            <button type="button" className="fill-btn circled"><i className="fa fa-shopping-cart" aria-hidden="true" /></button>
                          </div>
                        </div>
                      </div>

                    </Grid>
                    <Grid sx={{ maxWidth: 'unset' }} item xs={3}>


                      <div className="item">
                        <div className="element">
                          <div className="blckbox">
                            <h6> 🧠</h6>
                          </div>
                          <img style={{width: "163px",borderRadius : "12px"}} src="/images/pir.jpg" alt="images" />
                          <h4> what we will do for Sitemaps in wp Headless </h4>
                          <p style={{color: "#000000"}}> Good Way To optimize On BackEnd 👊 </p>
                          <div className="priceBox">

                            <h4>Now, if you want an api site that was previously WordPress and indexed in Google, this is important. </h4>
                          </div>
                          <ul className="stars">
                            <li>
                              <a href="#"> <i className="fa fa-star" aria-hidden="true" /> </a>
                            </li>
                            <li>
                              <a href="#"> <i className="fa fa-star" aria-hidden="true" /> </a>
                            </li>
                            <li>
                              <a href="#"> <i className="fa fa-star" aria-hidden="true" /> </a>
                            </li>
                            <li>
                              <a href="#"> <i className="fa fa-star" aria-hidden="true" /> </a>
                            </li>
                            <li>
                              <a href="#"> <i className="fa fa-star-o" aria-hidden="true" /> </a>
                            </li>
                          </ul>
                          <div className="buy-buttons">
                            <a target="_blank" href="https://wordpress.org/support/topic/xml-sitemap-for-headless-wordpress/" >      <button type="button" className="fill-btn">See Article</button></a>
                            <button type="button" className="fill-btn circled"><i className="fa fa-heart" aria-hidden="true" /></button>
                            <button type="button" className="fill-btn circled"><i className="fa fa-shopping-cart" aria-hidden="true" /></button>
                          </div>
                        </div>
                      </div>

                    </Grid>



                  </Grid>

























































                </div>
              </div>
            </div>
          </section>
          {/* Products Section Ends */}
          {/* Call To Action Section Begins */}
          <section id="call-to-action" className="call-to-action pad-top-115 pad-bottom-115">
            <div style={{"background":"url(images/rr.jpg) no-repeat center"}} className="actionBg parallax" />
            <div className="container pr">
              <div className="secHead wow fadeInLeft animated" data-wow-delay="0.5s">
                <div className="skewpink" />
                <div className="row">
                  <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                    <div className="titleText">
                      <h6 className="titleTop">so ok , ok now i Confess</h6>
                      <h2 className="sectionTitle">70% <span>backend Position</span></h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                  <p className="procap"> So maybe you're wondering if I'm finally doing ّFront_end or Back_end? After all, I definitely do not have 20 years of experience to be a full stack, well, I work in Back_end, if you read my resume, you will understand exactly </p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 wow animated fadeInUp" data-wow-delay="0.5s">
                 <a href="mailto:myntunescom@gmail.com" target="_blank" ><button  type="button" className="fill-btn">Contact to me Now!</button></a>
                </div>
              </div>
            </div>
          </section>
          {/* Call To Action Section Ends */}
          {/* Plans Section Begins */}
          <section id="plans" className="plans pad-top-115 pad-bottom-120">
            <div className="container pr">
              <div className="secHead wow fadeInLeft animated" data-wow-delay="0.5s">
                <div className="skewpink" />
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div className="titleText">
                      <h6 className="titleTop">READY TO GO?</h6>
                      <h2 className="sectionTitle">CHOOSE  <span>  My PLAN</span></h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="pricing-tables pad-top-55">
                  <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 no-padding pricebox">
                    <div className="element">
                      <div className="plan-top">
                        {/*<div class="skewpink"></div>*/}
                        <h5> Remote Full Time </h5>
                      </div>
                      <div className="plan-side">
                        <div className="skewback">
                          <h2> $0 <sub>/mo.</sub></h2>
                          <h6> compromise  </h6>
                        </div>
                      </div>
                      <ul className="plan-list">
                        <li> FullTime of Week </li>
                        <li> Good Interaction Remote Team </li>
                        <li> High access Available </li>
                        <li> Interact in the interview </li>
                      </ul>
                     <a target="_blank" href="https://api.whatsapp.com/send/?phone=09021991642"> <button   type="button" className="fill-btn">Chat On WhatsApp</button></a>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 no-padding pricebox midprice">
                    <div className="element midelement">
                      <div className="plan-top">
                        {/*<div class="skewpink"></div>*/}
                        <h5>  Presence Full time work </h5>
                      </div>
                      <div className="plan-side">
                        <div className="skewback">

                          <h6> COMPROMISE </h6>
                        </div>
                      </div>
                      <div className="midIcon"><img src="images/punch.png" className="flaticon-gym fIcons" /></div>
                      <ul className="plan-list">
                        <li> 26 Days a Month </li>
                        <li> SponsorShip Visa Potential </li>
                        <li> Better efficiency </li>
                        <li> Higher output </li>
                      </ul>
                      <a target="_blank" href="https://api.whatsapp.com/send/?phone=09021991642"><button  type="button" className="fill-btn">Chat On WhatsApp</button></a>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 no-padding pricebox">
                    <div className="element">
                      <div className="plan-top">
                        {/*<div class="skewpink"></div>*/}
                        <h5> Project Work </h5>
                      </div>
                      <div className="plan-side">
                        <div className="skewback">
                          <h2> $0<sub>/mo.</sub></h2>
                          <h6> compromise  </h6>
                        </div>
                      </div>
                      <ul className="plan-list">
                        <li> Contact ME </li>
                        <li> Contact ME </li>
                        <li> Contact ME </li>
                        <li> Contact ME </li>
                      </ul>
                      <a target="_blank" href="https://api.whatsapp.com/send/?phone=09021991642"> <button   type="button" className="fill-btn">Chat On WhatsApp</button></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* Plans Section Ends */}
          {/* testimonial Section Begins */}

          {/* testimonial Section Ends */}
          {/* news Section Begins */}
          <section id="news" className="news products pad-top-115 pad-bottom-110">
            <div className="container pr">
              <div className="secHead wow fadeInLeft animated" data-wow-delay="0.5s">
                <div className="skewpink" />
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div className="titleText">
                      <h6 className="titleTop">READY TO GO?</h6>
                      <h2 className="sectionTitle">good <span>Articles</span></h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row wow animated fadeInUp" data-wow-delay="0.5s">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <p className="procap"> When I'm bored, I're posting content I made myself , I try to have content that does not have much training in Google, at least in my own country, for example, full Frontity training is not in our country, even YouTube is not strong. </p>
                </div>





<div style={{textAlign : "center"}} className="container pr">

                <Grid  alignItems="center"
                       justify="center" container spacing={2} columns={16} sx={{ flexDirection: { xs: "column", md: "row"} }}>
                  <Grid sx={{ maxWidth: 'unset' }} item xs={4}>
                    <div className="item">
                      <figure> <img src="/images/reth.jpg" alt="images" /> </figure>
                      <h4> <a target="_blank" href="https://www.youtube.com/watch?v=v7vqJQSa-3k&t=15s"> RethinkDb tutorials </a> </h4>
                      <ul>
                        <li> <a href="#"> #No sql </a> </li>
                        <li> <a href="#"> #RethinkDb </a> </li>
                        <li> <a href="#"> #ReQl</a> </li>
                      </ul>
                      <div className="news-comments">
                        <a href="#"> <i className="fa fa-comment" aria-hidden="true" /> 3 </a>
                        <a href="#"> <i className="fa fa-eye" aria-hidden="true" /> 50 </a>
                      </div>
                    </div>
                  </Grid>
                  <Grid sx={{ maxWidth: 'unset' }} item xs={4}>
                    <div className="item">
                      <figure> <img src="/images/soon.jpg" alt="images" /> </figure>
                      <h4> <a href="#"> Laravel good orm and Factory Pattern </a> </h4>
                      <ul>
                        <li> <a href="#"> #soonVideo </a> </li>
                        <li> <a href="#"> #soonVideo </a> </li>
                        <li> <a href="#"> #soonVideo</a> </li>
                      </ul>
                      <div className="news-comments">
                        <a href="#"> <i className="fa fa-comment" aria-hidden="true" /> 0 </a>
                        <a href="#"> <i className="fa fa-eye" aria-hidden="true" /> 0 </a>
                      </div>
                    </div>
                  </Grid> <Grid sx={{ maxWidth: 'unset' }} item xs={4}>
                    <div className="item">
                      <figure> <img src="/images/soon.jpg" alt="images" /> </figure>
                      <h4> <a href="#"> NextJs Seo </a> </h4>
                      <ul>
                        <li> <a href="#"> #soonVideo </a> </li>
                        <li> <a href="#"> #soonVideo </a> </li>
                        <li> <a href="#"> #soonVideo</a> </li>
                      </ul>
                      <div className="news-comments">
                        <a href="#"> <i className="fa fa-comment" aria-hidden="true" /> 0 </a>
                        <a href="#"> <i className="fa fa-eye" aria-hidden="true" /> 0 </a>
                      </div>
                    </div>
                  </Grid><Grid sx={{ maxWidth: 'unset' }} item xs={4}>
                    <div className="item">
                      <figure> <img src="/images/soon.jpg" alt="images" /> </figure>
                      <h4> <a href="#"> wp rest api </a> </h4>
                      <ul>
                        <li> <a href="#"> #soonVideo </a> </li>
                        <li> <a href="#"> #soonVideo </a> </li>
                        <li> <a href="#"> #soonVideo</a> </li>
                      </ul>
                      <div className="news-comments">
                        <a href="#"> <i className="fa fa-comment" aria-hidden="true" /> 0 </a>
                        <a href="#"> <i className="fa fa-eye" aria-hidden="true" /> 0 </a>
                      </div>
                    </div>
                  </Grid>
                </Grid>
</div>


























































                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div className="news-fill-btn">
                    <a href="https://www.youtube.com/channel/UCtTYf89-CVu4zhKEwzoZrnw" className="fill-btn">View My youtube channel</a>
                  </div>
                </div></div></div>


          </section>
          {/* news Section Ends */}
          {/* footer Section Begins */}
          <section id="footer" className="footer pad-top-120 pad-bottom-120">
            <div className="footerBg" />
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div className="footer_logo">
                    <figure><img id="imgfooter" src="images/footer_morisfa.png" alt="image" /></figure>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                  <div className="contact-form btm-brdr">
                    <div className="form-Box">
                      <input type="text" placeholder="Name" className="b_effect" />
                      <span className="focus-border">
                        <i />
                      </span>
                    </div>
                    <div className="form-Box">
                      <input type="text" placeholder="Email" className="b_effect" />
                      <span className="focus-border">
                        <i />
                      </span>
                    </div>
                    <div className="form-Box">
                      <textarea placeholder="Message" className="b_effect" defaultValue={""} />
                      <span className="focus-border">
                        <i />
                      </span>
                    </div>
                    <div className="form-Box">
                      <input  onClick={() => this.setState({ status: true })} type="submit" defaultValue="Submit" className="fill-btn" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                  <div className="contact-address btm-brdr">
                    <h4 className="fTitle"> Contact <span>Me</span> </h4>
                    <ul>s
                      <li> <i className="fa fa-phone" aria-hidden="true" /> <span>+98 902 199 1642</span> </li>
                      <li> <i className="fa fa-map-marker" aria-hidden="true" /> <span>Iran - Tehran - Chitgar</span> </li>
                      <li>
                        <a href="#"> <i className="fa fa-envelope-o" aria-hidden="true" /> <span>Myntunescom@gmail.com</span> </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                  <div className="subscribe btm-brdr">
                    <h4 className="fTitle"> Subscribe <span>My Linked In</span> </h4>
                    <form>

                    <a target="_blank" href="https://linkedin.com/in/morisfa" > <button  type="button" className="fill-btn">Subscribe</button></a>
                    </form>
                  </div>
                  <div className="social-icons">
                    <h4 className="fTitle"> Social <span>News</span> </h4>
                    <ul>
                      <li>
                        <a href="'#" target="_blank"> <i className="fa fa-facebook" aria-hidden="true" /> </a>
                      </li>
                      <li>
                        <a href="'#" target="_blank"> <i className="fa fa-twitter" aria-hidden="true" /> </a>
                      </li>
                      <li>
                        <a href="'#" target="_blank"> <i className="fa fa-youtube-play" aria-hidden="true" /> </a>
                      </li>
                      <li>
                        <a href="'#" target="_blank"> <i className="fa fa-instagram" aria-hidden="true" /> </a>
                      </li>
                      <li>
                        <a href="'#" target="_blank"> <i className="fa fa-google-plus" aria-hidden="true" /> </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* footer Section Ends */}
          {/* copyright Section Begins */}
          <section id="copyright" className="copyright">
            <div className="container wow animated fadeInUp" data-wow-delay="0.5s">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <h6> © 2016 Fitness Point is powered by <a href="https://store.multidots.com/" target="_blank">DotStore.</a> </h6>
                </div>
              </div>
            </div>
          </section>
          {/* copyright Section Ends */}
          {/* Back to top Section Begins */}
          <a href="javascript:void(0);" className="back-to-top"><i className="fa fa-long-arrow-up" aria-hidden="true" /></a>
          {/* Back to top Section Ends */}
        </div>




        <div className="mobilebar-container mobile-layout">
          <div
              style={{
                boxShadow: "0 10px 6px 0 rgba(0, 0, 0, 0.175)",
                borderRadius: 6,
                border: "10px solid #fff"
              }}
          >
            <nav className="mobilebar-tab">
              <div id="applicationBarMobileButton1" className="mobilebar-tab-item active">
                <span className="mobilebar-tab__icon">
                 <a target="_blank" href="https://linkedin.com/in/morisfa"> <img src="/images/in.png" className="fas fa-home" /></a>
                </span>
              </div>
              <div className="mobilebar-tab-item">
                <span className="mobilebar-tab__icon">
                  <a target="_blank" href="https://www.youtube.com/channel/UCtTYf89-CVu4zhKEwzoZrnw">  <img src="images/you.png" className="fas fa-search" /></a>
                </span>
              </div>
              <div className="mobilebar-tab-item">
                <span className="mobilebar-tab__icon">
                  <a target="_blank" href="https://morisfa.com/cv/cv.pdf">  <img id="resume" src="images/cv.png" className="fas fa-search" /></a>
                </span>
              </div>
              <div className="mobilebar-tab-item">
                <span className="mobilebar-tab__icon">
                  <a target="_blank" href="mailto:myntunescom@gmail.com">   <img src="images/gmail.png" className="fas fa-search" /></a>
                </span>
              </div>
              <div className="mobilebar-tab-item">
                <span className="mobilebar-tab__icon">
                  <a target="_blank" href="https://api.whatsapp.com/send/?phone=09021991642">  <img src="images/call.png" className="fas fa-search" /></a>

                </span>
              </div>
              <div className="mobilebar-tab-overlay" />
            </nav>
          </div>
          <p id="bby">
            designed just Funny without look ux(🧐) by morisfa - React.js V17
          </p>
        </div>







      </div>






  );
} }

export default App;
